import { 
  DEFAULT_TFJS_BACKEND, 
  MODEL_CONFIGS
} from '../utils/constants';

function ModelCard({ modelName, onPauseClicked, isRunning }) {
  return (
    <div className='text-left text-sm max-w-md relative md:pt-56.25% pt-0 rounded p-2 bg-gray-600 h-full w-full'>
    <br/>
    <b>Model Info</b> <br/>
    ----------------------------- <br/>
    Backend: {DEFAULT_TFJS_BACKEND} <br/>
    Input shape: [{MODEL_CONFIGS[modelName].inputHeight}, {MODEL_CONFIGS[modelName].inputWidth}] <br/>
    Output Type: {MODEL_CONFIGS[modelName].outputType} <br/>
    Label Ontology: {MODEL_CONFIGS[modelName].ontology} <br/>
    Training Dataset: {MODEL_CONFIGS[modelName].trainingDataset}
    <br/>
    <br/>
    <div className="pt-3">
      <button className="bg-white hover:bg-gray-100 text-gray-800 border-gray-400 font-semibold py-2 px-4 border rounded shadow text-xs"
      onClick={onPauseClicked}>
      {isRunning ? "Stop" : "Start" }
      </button>
    </div>
    </div>
  );
}

export default ModelCard;