// Types an constant definitions

export const VideoSource = {
  Webcam: 'Webcam',
  VideoFile: 'Video File',
};

export const VIDEO_LIST = [
  "Video1.mp4",
  "Video2.mp4",
  "Video3.mp4",
  "Video4.mp4",
  "Video5.mp4",
  "Video6.mp4",
  "Video7.mp4",
  "Video8.mp4",
  "Video9.mp4",
  "Video10.mp4",
];

export const keypointNames = [
  'nose',
  'leftEye',
  'rightEye',
  'leftEar',
  'rightEar',
  'leftShoulder',
  'rightShoulder',
  'leftElbow',
  'rightElbow',
  'leftWrist',
  'rightWrist',
  'leftHip',
  'rightHip',
  'leftKnee',
  'rightKnee',
  'leftAnkle',
  'rightAnkle',
];

/// Pose model configurations
export const PoseModelOutputType = {
  HEATMAP: 'Heatmap',
  SIMCC: 'SimCC',
  REGRESSION: 'Regression'
}

export const MODEL_CONFIGS = {
  "mobilenet": {
    'inputWidth': 192,
    'inputHeight': 256,
    'outputType': PoseModelOutputType.HEATMAP,
    'ontology': "Coco Keypoints",
    'trainingDataset': "Coco",
  },
  "blazeposeH": {
    'inputWidth': 256,
    'inputHeight': 256,
    'outputType': PoseModelOutputType.HEATMAP,
    'ontology': "Coco Keypoints",
    'trainingDataset': "Coco",
  },
  // "blazeposeR": {
  //   'inputWidth': 256,
  //   'inputHeight': 256,
  //   'outputType': PoseModelOutputType.REGRESSION,
  //   'ontology': "Coco Keypoints",
  //   'trainingDataset': "Coco",
  // },
  "rtmpose": {
    'inputWidth': 192,
    'inputHeight': 256,
    'outputType': PoseModelOutputType.SIMCC,
    'ontology': "Coco Keypoints",
    'trainingDataset': "Coco",
  },
  "rtmpose_aic": {
    'inputWidth': 192,
    'inputHeight': 256,
    'outputType': PoseModelOutputType.SIMCC,
    'ontology': "Coco Keypoints",
    'trainingDataset': "Coco + AIC",
  },
}

export const MODEL_LIST = Object.keys(MODEL_CONFIGS);
export const DEFAULT_POSE_MODEL = MODEL_LIST[0];

export const PipelineType = {
	DetectAndPose: 0,
	PoseOnly: 1,
	PoseAndTrack: 2,
};

// Confidence thresholds
export const DEFAULT_KEYPOINT_CONFIDENCE_THRESHOLD = 0.3;

// One-euro filter configurations (keypoint trajectory smoothing)
export const DEFAULT_KEYPOINT_TRAJECTORY_SMOOTHING_ENABLED = true;
export const KEYPOINT_TRAJECTORY_SMOOTHING_STRENGTH = 6; 
// export const ONE_EURO_FILTER_DX0 = 0.6;
export const ONE_EURO_FILTER_DX0 = 1.0;
// export const ONE_EURO_FILTER_MIN_CUTOFF = 0.015;
export const ONE_EURO_FILTER_MIN_CUTOFF = 0.01;

// Model execution
export const DEFAULT_TFJS_BACKEND = 'webgpu';