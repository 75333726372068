import { 
    ONE_EURO_FILTER_DX0, 
    ONE_EURO_FILTER_MIN_CUTOFF, 
    KEYPOINT_TRAJECTORY_SMOOTHING_STRENGTH 
} from '../utils/constants.js';

// One Euro Filter implementation. Based partly on https://github.com/jaantollander/OneEuroFilter 

class OneEuroFilter {
    // Smooths keypoint trajectories. Refactored to be stateful - keeps 
    // track of timestep internally for you. Additionally the ability to keep
    // track of time has been removed. This can be easily brought back if needed; 
    // If we want to track keypoints through skipped detections, but for now, if a 
    // keypoint detection is dropped, the track is reset.
    constructor(x0, dx0 = 0.0, min_cutoff = 1.0, beta = 0.0, d_cutoff = 1.0) {
        // Initialize the one euro filter.
        this.min_cutoff = min_cutoff;
        this.beta = beta;
        this.d_cutoff = d_cutoff;

        // For resetting.
        this.dx0 = dx0;

        // Previous values.
        this.x_prev = x0;
        this.dx_prev = dx0;
    }

    smoothing_factor(t_e, cutoff) {
        const r = 2 * Math.PI * cutoff * t_e;
        return r / (r + 1);
    }
    
    exponential_smoothing(a, x, x_prev) {
        return a * x + (1 - a) * x_prev;
    }

    update(x) {
        /// Computes the filtered signal.

        // The filtered derivative of the signal.
        const t_e = KEYPOINT_TRAJECTORY_SMOOTHING_STRENGTH; // This is not actually what t_e is, but it's what we're using it for.
        const a_d = this.smoothing_factor(t_e, this.d_cutoff);
        const dx = (x - this.x_prev) / t_e;
        const dx_hat = this.exponential_smoothing(a_d, dx, this.dx_prev);
        
        // The filtered signal.
        const cutoff = this.min_cutoff + this.beta * Math.abs(dx_hat);
        const a = this.smoothing_factor(t_e, cutoff);
        const x_hat = this.exponential_smoothing(a, x, this.x_prev);

        // Memorize the previous values.
        this.x_prev = x_hat;
        this.dx_prev = dx_hat;

        return x_hat;
    }

    reset(x0) {
        this.x_prev = x0;
        this.dx_prev = this.dx0;
    }
}


export class KeypointTracker {
    constructor(keypoints) {
        this.dx0 = ONE_EURO_FILTER_DX0;
        this.min_cutoff = ONE_EURO_FILTER_MIN_CUTOFF;
        
        this.x_track = Array.from({ length: keypoints.length }, (_, kpt) => new OneEuroFilter(keypoints[kpt][0], this.dx0, this.min_cutoff));
        this.y_track = Array.from({ length: keypoints.length }, (_, kpt) => new OneEuroFilter(keypoints[kpt][1], this.dx0, this.min_cutoff));
    }

    resetKeypointTrack(keypointIndex, keypoint) {
        this.x_track[keypointIndex].reset(keypoint[0]);
        this.y_track[keypointIndex].reset(keypoint[1]);
    }

    updateKeypointTrack(keypointIndex, keypoint)  {
        keypoint[0] = this.x_track[keypointIndex].update(keypoint[0]);
        keypoint[1] = this.y_track[keypointIndex].update(keypoint[1]);
        return keypoint;
    }
}
