import * as posenet from "@tensorflow-models/posenet";
 
const keypointColors = {
  'nose': "#ff0000",
  'leftEye': "#ff8000",
  'rightEye': "#ffff00",
  'leftEar': "#80ff00",
  'rightEar': "#00ff00",
  'leftShoulder': "#00ff80",
  'rightShoulder': "#00ffff",
  'leftElbow': "#0080ff",
  'rightElbow': "#0000ff",
  'leftWrist': "#8000ff",
  'rightWrist': "#ff00ff",
  'leftHip': "#ff0080",
  'rightHip': "#ff0000",
  'leftKnee': "#ff8000",
  'rightKnee': "#ffff00",
  'leftAnkle': "#80ff00",
  'rightAnkle': "#00ff00",
};

const lineWidth = 2;

// function drawPoint(canvasContext, y, x, r, color, confidence) {
//   canvasContext.beginPath();
//   canvasContext.arc(x, y, r, 0, 2 * Math.PI);
//   canvasContext.fillStyle = color;
//   canvasContext.fill();
// }

// Keypoint confidence displayed through opacity
// function drawPoint(canvasContext, y, x, r, color, confidence) { 
//   const alpha = Math.min(1, confidence); // Limit the alpha value to 1
//   const rgbaColor = color.replace(')', `, ${alpha})`).replace('rgb', 'rgba'); // Convert 'rgb' to 'rgba' and apply the alpha value

//   canvasContext.beginPath();
//   canvasContext.arc(x, y, r, 0, 2 * Math.PI);
//   canvasContext.fillStyle = rgbaColor;
//   canvasContext.fill();
// }

// Keypoint confidence displayed through size of circle
function drawPoint(canvasContext, y, x, r, color, confidence) {
  const sizeFactor = Math.min(1, confidence); // Limit the size factor to 1
  const newSize = r * sizeFactor;

  canvasContext.beginPath();
  canvasContext.arc(x, y, newSize, 0, 2 * Math.PI);
  canvasContext.fillStyle = color;
  canvasContext.fill();
}

function drawPointWithOpacity(canvasContext, y, x, r, color, confidence) {
  const alpha = Math.min(1, confidence); // Limit the alpha value to 1
  const rgbaColor = color.replace(')', `, ${alpha})`).replace('rgb', 'rgba'); // Convert 'rgb' to 'rgba' and apply the alpha value

  canvasContext.beginPath();
  canvasContext.arc(x, y, r, 0, 2 * Math.PI);
  canvasContext.fillStyle = rgbaColor;
  canvasContext.fill();
}

function drawPointWithSize(canvasContext, y, x, r, color, confidence) {
  const sizeFactor = Math.min(1, confidence); // Limit the size factor to 1
  const newSize = r * sizeFactor;

  canvasContext.beginPath();
  canvasContext.arc(x, y, newSize, 0, 2 * Math.PI);
  canvasContext.fillStyle = color;
  canvasContext.fill();
}
 
function drawSegment([ay, ax], [by, bx], color, scale, canvasContext) {
  canvasContext.beginPath();
  canvasContext.moveTo(ax * scale, ay * scale);
  canvasContext.lineTo(bx * scale, by * scale);
  canvasContext.lineWidth = lineWidth;
  canvasContext.strokeStyle = color;
  canvasContext.stroke();
}

function drawKeypoints(keypoints, minConfidence, canvasContext, scale = 1) {
  for (let i = 0; i < keypoints.length; i++) {
    const keypoint = keypoints[i];

    if (keypoint.score < minConfidence) {
      continue;
    }

    const { y, x } = keypoint.position;
    const color = keypointColors[keypoint.part];
    drawPoint(canvasContext, y * scale, x * scale, 7, color, keypoint.score);
  }
}

function drawSkeleton(keypoints, minConfidence, canvasContext, scale = 1) {
  const adjacentKeyPoints = posenet.getAdjacentKeyPoints(
    keypoints,
    minConfidence
  );

  adjacentKeyPoints.forEach((keypoints) => {

    const toTuple = ({ y, x }) => {
      return [y, x];
    }

    const color = keypointColors[keypoints[0].part];

    drawSegment(
      toTuple(keypoints[0].position),
      toTuple(keypoints[1].position),
      color,
      scale,
      canvasContext
    );
  });
}

const drawRect = (bbox, canvasContext) => {
  // Extract boxes and classes
  const [x, y, width, height] = bbox;
  const text = 'person'; 

  // Set styling
  canvasContext.strokeStyle = '#000000'
  canvasContext.font = '18px Arial';

  // Draw rectangles and text
  canvasContext.beginPath();   
  canvasContext.fillStyle = '#000000'
  canvasContext.fillText(text, x, y);
  canvasContext.rect(x, y, width, height); 
  canvasContext.stroke();
}

async function animateArmatureOnCanvas(canvasContext, keypoints, keypointConfidenceThreshold, bbox, frameWidth, frameHeight) {
  canvasContext.clearRect(0, 0, frameWidth, frameHeight);

  return new Promise((resolve, reject) => {
    try {
      requestAnimationFrame(() => {
        
        if (bbox !== null) {
          drawRect(bbox, canvasContext);
        }

        if (keypoints !== null) {
          drawKeypoints(keypoints, keypointConfidenceThreshold, canvasContext);
          drawSkeleton(keypoints, keypointConfidenceThreshold, canvasContext);
        }

        resolve();
      });
    } catch (e) {
      reject(e);
    }
  });
}

export default animateArmatureOnCanvas;