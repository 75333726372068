import ReactPlayer from 'react-player';
import Webcam from "react-webcam";

import { VideoSource } from '../utils/constants';

// This component renders either our Webcam media player 
// or our Video File media player
function MediaPlayer(webcamRef, playerRef, videoSource, video) {
  if (videoSource == VideoSource.Webcam) {

    const webcamComponent = (
      <Webcam
        ref={webcamRef}
        muted={true}
        // mirrored={true}
      />
    );

    // Only allow switch to webcam is webcam is available
    // if (!(
    //   typeof webcamRef.current !== "undefined" && 
    //   webcamRef !== null && 
    //   webcamRef.current !== null &&
    //   webcamRef.current.video.readyState === 4
    // )) {

    //   if (typeof webcamRef.current === "undefined") {
    //     console.log("CASE 1")
    //   } else if (webcamRef === null) {
    //     console.log("CASE 2")
    //   } else if (webcamRef.current === null) {
    //     console.log("CASE 3")
    //   } else if (webcamRef.current.video.readyState !== 4) {
    //     console.log("CASE 4")
    //   }

    //   alert("Webcam is unavailable. Please give the application permission or ensure a webcam is connected.");
    //   return;
    // }


    return webcamComponent;
  } else {
    return (
      <ReactPlayer
        ref={playerRef}
        className="react-player"
        url={'videos/' + video}
        playsinline={true}
        controls={false}
        playing={true}
        loop={true}
        pip={false}
        muted={true}
        width="100%"
        height="100%"
      />
    );
  }
}

// This Component layers our Media Player with our Canvas
function VideoCanvas({ webcamRef, playerRef, videoSource, currentVideo, canvasRef }) {
  return (
    <div className="relative w-full max-w-md md:pt-56.25% pt-0">
    {MediaPlayer(webcamRef, playerRef, videoSource, currentVideo)}
    <canvas
      id='canvas'
      ref={canvasRef}
      className="absolute top-0 left-0 w-full h-full"
    />
    </div>
  );
}

export default VideoCanvas;