// External libraries
import React from 'react';
import { useRef, useState, useEffect } from 'react';

// Internal modules
import './App.css';
import Settings from './components/Settings';
import refreshStatsPanel from './components/StatsPanel';
import VideoCanvas from './components/VideoCanvas';
import ModelCard from './components/ModelCard';
import { 
  PipelineType, 
  VideoSource, 
  VIDEO_LIST, 
  DEFAULT_KEYPOINT_TRAJECTORY_SMOOTHING_ENABLED, 
  DEFAULT_KEYPOINT_CONFIDENCE_THRESHOLD, 
  DEFAULT_POSE_MODEL, 
} from './utils/constants';
import startInferenceLoop from './AI/runner';


function App() {
  // State
  const [isRunning, setIsRunning] = useState(true);

  const [modelSettings, setModelSettings] = useState({
    'pipelineType': PipelineType.PoseAndTrack,
    'modelName': DEFAULT_POSE_MODEL,
    'keypointSmoothingMode': DEFAULT_KEYPOINT_TRAJECTORY_SMOOTHING_ENABLED,
    'keypointConfidenceThreshold': DEFAULT_KEYPOINT_CONFIDENCE_THRESHOLD,
    'debugMode': false
  })

  const [videoSettings, setVideoSettings] = useState({
    'videoSource': VideoSource.VideoFile,
    'currentVideo': VIDEO_LIST[0],
  })

  // Refs
  const webcamRef = useRef(null);
  const playerRef = useRef(null);
  const canvasRef = useRef(null);
  const isRunningRef = useRef(isRunning);

  // Stats Panel
  const stats = refreshStatsPanel(document)
  

  // Effects

  // Used to notify a running inference process when to stop
  useEffect(() => {
    isRunningRef.current = isRunning;
  }, [isRunning]);

  // Kicks off an inference loop
  useEffect(() => {
    if (isRunning) {

      // Get video stream
      // let video;
      // if (videoSettings.videoSource == VideoSource.Webcam) { 
      //   video = webcamRef.current.video;
      // } else {
      //   video = playerRef.current.getInternalPlayer();
      // }

      // // Setup canvas
      // canvasRef.current.width = video.videoWidth;
      // canvasRef.current.height = video.videoHeight;
      // const canvasContext = canvasRef.current.getContext("2d");

      // startInferenceLoop(
      //   video,
      //   modelSettings,
      //   isRunningRef,
      //   canvasContext,
      //   stats
      // );

      startInferenceLoop(modelSettings, videoSettings.videoSource, isRunningRef, webcamRef, playerRef, canvasRef, stats);
    }
  }, [isRunning]);

  // Settings callbacks

  const updatemodelSettings = (newSettings) => {
    setIsRunning(false);
    setModelSettings({...modelSettings, ...newSettings})
  }

  const updateVideoSettings = (newSettings) => {
    setIsRunning(false);
    setVideoSettings({...videoSettings, ...newSettings})
  }

  const onPauseClicked = () => {
    setIsRunning(!isRunning);
  }

  return (
    <div className="App">
      <header className='App-header'>
        <div className="flex flex-col w-full gap-3">
          <div className="w-full p-1">
            <Settings
              currentVideoSettings={videoSettings}
              setVideoSettings={updateVideoSettings}
              currentmodelSettings={modelSettings}
              setmodelSettings={updatemodelSettings}
            />
          </div>  
          <div className="flex flex-wrap w-full" >
            <div className="w-full md:w-1/2 p-2 flex justify-center items-center">
              <VideoCanvas 
                webcamRef={webcamRef} 
                playerRef={playerRef} 
                videoSource={videoSettings.videoSource} 
                currentVideo={videoSettings.currentVideo} 
                canvasRef={canvasRef} 
              />
            </div>
            <div className="w-full md:w-1/2 p-2 flex justify-center items-center">
              <ModelCard 
                modelName={modelSettings.modelName} 
                onPauseClicked={onPauseClicked} 
                isRunning={isRunning} 
              />
            </div>          
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;