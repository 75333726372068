import { VideoSource, PipelineType, VIDEO_LIST, MODEL_LIST } from '../utils/constants';


// Component for selecting the video source
function VideoSourcePicker({currentVideoSource, setVideoSource}) {
  return (
    <div>
      <label className="mr-2">
        Video Source: 
      </label>
      <select 
        value={currentVideoSource} 
        onChange={(event) => setVideoSource(event.target.value)} 
        className="text-black"
      >
        <option value={VideoSource.Webcam}>Webcam</option>
        <option value={VideoSource.VideoFile}>Video File</option>
      </select>
    </div>
  );
}
  
// Component for selecting the pipeline type
function PipelinePicker({currentPipelineType, setPipelineType}) {
  return (
    <div>
      <label className="mr-2">
        Pipeline: 
      </label>
      <select 
        value={currentPipelineType} 
        onChange={(event) => setPipelineType(event.target.value)} 
        className="text-black"
      >
        <option value={PipelineType.PoseAndTrack}>Pose and Track</option>
        <option value={PipelineType.DetectAndPose}>Detect and Pose</option>
        <option value={PipelineType.PoseOnly}>Pose Only (bad)</option>
      </select>
    </div>
  );
}

// Component for selecting the video file
function VideoPicker({ currentVideo, setVideo }) {  
  return (
    <div>
      <label className="mr-2">
        Video:
      </label>
      <select 
        value={currentVideo} 
        onChange={(event) => setVideo(event.target.value)} 
        className="text-black"
      >
        {VIDEO_LIST.map((video, index) => (
          <option key={index} value={video}>
            {video}
          </option>
        ))}
      </select>
    </div>
  );
}

// Component for selecting the pose model
function ModelPicker({ currentModel, setModel }) {  
  return (
    <div>
      <label className="mr-2">
        Model:
      </label>
      <select 
          value={currentModel} 
          onChange={(event) => setModel(event.target.value)} 
          className="text-black"
        >
        {MODEL_LIST.map((video, index) => (
          <option key={index} value={video}>
            {video}
          </option>
        ))}
      </select>
    </div>
  );
}

// Component for enabling / disabling keypoint trajectory smoothing
function KeypointSmoothingToggle({ currentKeypointSmoothingMode, setKeypointSmoothingMode }) {
  return (
    <div>
      <label className="mr-2">
        Keypoint Smoothing:
      </label>
      <input
        type="checkbox"
        checked={currentKeypointSmoothingMode} 
        onChange={(event) => setKeypointSmoothingMode(event.target.checked)}
        className="form-checkbox text-green-500 h-5 w-5"
      />
    </div>
  );
}

// Component for controlling keypoint confidence threshold
function KeypointConfidenceThresholdSlider({ currentConfidenceThreshold, setConfidenceThreshold }) {
  return (
    <div>
      <label className="mr-2">
        Kpt Confidence Threshold: {currentConfidenceThreshold.toFixed(2)}
      </label>
      <input
        type="range"
        min="0"
        max="1"
        step="0.05"
        value={currentConfidenceThreshold}
        onChange={(event) => setConfidenceThreshold(Number(event.target.value))}
        className="slider"
      />
    </div>
  );
}

// Component for toggling debug mode
function DebugModeToggle({ currentDebugMode, setDebugMode }) {
  return (
    <div>
      <label className="mr-2">
        Debug Mode:
      </label>
      <input
        type="checkbox"
        checked={currentDebugMode} 
        onChange={(event) => setDebugMode(event.target.checked)}
        className="form-checkbox text-green-500 h-5 w-5"
      />
    </div>
  );
}

// Container for all of our pipeline setting components
function Settings({currentVideoSettings, setVideoSettings, currentmodelSettings, setmodelSettings}) {
  return (
    <div className="flex flex-wrap text-sm w-full rounded p-2">
      <fieldset className="w-full md:w-1/3 p-2 border rounded">
        <legend className="font-bold">Video Settings</legend>
        <div className="w-full">
          <VideoSourcePicker
            currentVideoSource={currentVideoSettings.videoSource}
            setVideoSource={(videoSource) => setVideoSettings({'videoSource': videoSource})}
          />
        </div>
        <div className="w-full mt-2">
          <VideoPicker 
            currentVideo={currentVideoSettings.currentVideo} 
            setVideo={(currentVideo) => setVideoSettings({'currentVideo': currentVideo})}
          />
        </div>
      </fieldset>
      <fieldset className="w-full md:w-1/3 p-2 border rounded">
        <legend className="font-bold">Model Settings</legend>
        <div className="w-full">
          <ModelPicker 
            currentModel={currentmodelSettings.modelName} 
            setModel={(modelName) => setmodelSettings({'modelName': modelName})}
          />
        </div>
        <div className="w-full mt-2">
          <PipelinePicker
            currentPipelineType={currentmodelSettings.pipelineType}
            setPipelineType={(pipelineType) => setmodelSettings({'pipelineType': pipelineType})}
          />
        </div>
        <div className="w-full mt-2">
          <DebugModeToggle
            currentDebugMode={currentmodelSettings.debugMode}
            setDebugMode={(debugMode) => setmodelSettings({'debugMode': debugMode})}
          />
        </div>
      </fieldset>
      <fieldset className="w-full md:w-1/3 p-2 border rounded">
        <legend className="font-bold">Post Processing</legend>
        <div className="w-full">
          <KeypointSmoothingToggle
            currentKeypointSmoothingMode={currentmodelSettings.keypointSmoothingMode}
            setKeypointSmoothingMode={(keypointSmoothingMode) => setmodelSettings({'keypointSmoothingMode': keypointSmoothingMode})}
          />
        </div>
        <div className="w-full mt-2">
          <KeypointConfidenceThresholdSlider
            currentConfidenceThreshold={currentmodelSettings.keypointConfidenceThreshold}
            setConfidenceThreshold={(keypointConfidenceThreshold) => setmodelSettings({'keypointConfidenceThreshold': keypointConfidenceThreshold})}
          />
        </div>
      </fieldset>
    </div>
  );
}

export default Settings;
