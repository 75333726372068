import Stats from 'stats.js';

function refreshStatsPanel(document) {
    var stats = new Stats();
    stats.showPanel(0); // 0: FPS panel, 1: MS panel, 2: MB panel, 3+: custom panel
    stats.dom.id = "statsPanel";
  
    // Every time the state of app changed, it would add a new statsPanel, so this code
    // removes the old one before adding the new one.
    var element = document.getElementById("statsPanel");
    if (element) {
      document.body.removeChild(element);
    }
  
    // Add new Stats Panel to dom
    document.body.appendChild(stats.dom);
    return stats
  }

export default refreshStatsPanel;