// External libraries
import * as tf from '@tensorflow/tfjs';
import * as ssd from '@tensorflow-models/coco-ssd';

// Internal modules
import { DEFAULT_TFJS_BACKEND, VideoSource } from '../utils/constants';
import animateArmatureOnCanvas from "../utils/draw";
import { infer, inferDebug } from './inference';


const runInferenceStep = async (video, detector, poseModel, modelSettings, canvasContext, isRunningRef, stats, prevPredictions=null) => {

  if (isRunningRef.current == false) {
    // End loop and clear canvas
    canvasContext.clearRect(0, 0, video.videoWidth, video.videoHeight);
    return
  }

  // 
  // Execute pipeline
  stats.begin();
  var predictions;
  if (modelSettings.debugMode) {
    predictions = await inferDebug(video, detector, poseModel, modelSettings, prevPredictions);
  } else {
    predictions = await infer(video, detector, poseModel, modelSettings, prevPredictions);
  }

  // 
  // Draw the armature
  await animateArmatureOnCanvas(canvasContext, predictions.keypoints, modelSettings.keypointConfidenceThreshold, predictions.bbox, video.videoWidth, video.videoHeight);
  stats.end();
  
  // Wait for next frame
  await new Promise(requestAnimationFrame);

  // Process next frame
  await runInferenceStep(video, detector, poseModel, modelSettings, canvasContext, isRunningRef, stats, predictions);
};

async function setTFBackend(backend) {
  const success = await tf.setBackend(backend);
  if (success) {
    console.log('Successfully set the backend to ' + backend);
  } else {
    console.log('Failed to set the backend to ' + backend + ". Using " + tf.getBackend() + " instead.");
  }
}

// export const startInferenceLoop = async (video, modelSettings, isRunningRef, canvasContext, stats) => {
async function startInferenceLoop(modelSettings, videoSource, isRunningRef, webcamRef, playerRef, canvasRef, stats) {

  setTFBackend(DEFAULT_TFJS_BACKEND);

  // Set up models
  const poseModel = await tf.loadGraphModel(`/peerpose/${modelSettings.modelName}/model.json`);
  const detector = await ssd.load({base: 'lite_mobilenet_v2'});

  const video =  (videoSource == VideoSource.Webcam) ? webcamRef.current.video : playerRef.current.getInternalPlayer();

  // Setup canvas
  canvasRef.current.width = video.videoWidth;
  canvasRef.current.height = video.videoHeight;
  const canvasContext = canvasRef.current.getContext("2d");

  // Infer
  runInferenceStep(video, detector, poseModel, modelSettings, canvasContext, isRunningRef, stats);
};

export default startInferenceLoop;